<template>
  <div id="bsModalUpdateAltCoinExchange" aria-labelledby="bsModalLabel" class="modal fade"
       data-backdrop="static" data-keyboard="false"
       @click="clickOverlay()"
       data-toggle="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span
              aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <input v-model="form.ID" type="hidden"/>
              <input v-model="form.Key" type="hidden"/>
              <ValidationProvider v-slot="{ errors }" class="form-group" :name="valueLabel.toLowerCase()" rules="required" tag="div">
                <label>{{ valueLabel }}</label>
                <input v-if="type === 'text'" v-model="form.Value" aria-label="" class="form-control" type="text"/>
                <select v-if="type === 'select'" v-model="form.Value" aria-label="" class="form-control">
                  <option v-for="(option, index) in options" :key="index" :value="extractValue(option, 'key')">
                    {{ extractValue(option, 'label') }}</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Save</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { logout, saveNotification } from "@/lib/utils";

export default {
  name: "UpdateAltCoinXChangeModal",
  data() {
    return {
      isLoading: false,
      title: "",
      valueLabel: "",
      type: "select",
      options: [],
      form: {
        MerchantID: "",
        APIKey: "",
        ID: "",
        Key: "",
        Value: "",
        output: "json",
      },
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    extractValue(option, type) {
      const key = type === "key" ? Object.keys(option)[0] : Object.keys(option)[1];
      return option[key];
    },
    confirm: function () {
      this.confirmHook();
      this.close();
    },
    onSubmit: function () {
      this.isLoading = true;
      const payload = JSON.parse(JSON.stringify(this.form));
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=AltCoinXChangeUpdate`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        this.isLoading = false;
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.confirm();
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalUpdateAltCoinExchange").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("update-xchange-modal", (args) => {
      this.title = args.title;
      const data = args.xChange;
      this.form = {
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: data.ID,
        Key: data.Key,
        Value: data.Value,
        output: "json",
      };
      this.confirmHook = args.confirm;
      $("#bsModalUpdateAltCoinExchange").modal("show");
      this.valueLabel = args.valueLabel;
      this.type = args.type;
      if (this.type === "select") {
        if (typeof this.form.Value === "boolean") {
          this.form.Value = this.form.Value ? 1 : 0;
        }
        this.options = args.options ? args.options : [];
      }
    });
  },
};
</script>

<style scoped></style>
