<template>
  <div id="bsModalBalanceRefreshDialog" aria-labelledby="bsModalBalanceRefreshDialogLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       role="dialog" tabindex="-1" @click="close()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-body">
          <div v-html="data"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BalanceRefreshResultDialog",
  data() {
    return {
      data: null,
    };
  },
  methods: {
    close() {
      $("#bsModalBalanceRefreshDialog").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("balance-refresh-dialog", (args) => {
      this.data = args.data;
      $("#bsModalBalanceRefreshDialog").modal("show");
    });
  },
};
</script>

<style scoped>

</style>
