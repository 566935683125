<template>
  <div v-if="!isLoading && coinHealth">
    <div class="box bt-0">
      <va-tabs ref="tabs" :padding="'padding: 10px 15px 20px'">
        <div slot="actions">
          <div v-if="coinHealth.Online" class="text-right mr-16">
            <button v-tooltip="{ content: 'Get Info', trigger: 'click hover focus' }"
              class="btn btn-info btn-sm btn-flat ml-8" @click="getInfo(coinHealth)">I
            </button>
            <button v-tooltip="{ content: 'PoolCheck Only', trigger: 'click hover focus' }"
              class="btn btn-info btn-sm btn-flat ml-8" @click="poolCheck(coinHealth)">
              <i class="fa fa-spinner fa-spin mr-8" v-if="loader === 'poolCheck'"></i>
              <span>P</span>
            </button>
            <button v-tooltip="{ content: 'PoolCheck & Consolidation', trigger: 'click hover focus' }"
              class="btn btn-info btn-sm btn-flat ml-8" @click="poolCheckAndConsolidation(coinHealth)">
              <i class="fa fa-spinner fa-spin mr-8" v-if="loader === 'consolidate'"></i>
              <span>C</span>
            </button>
            <router-link v-tooltip="{ content: 'Balances outstanding', trigger: 'click hover focus' }"
              :to="{ name: 'payouts', query: { id: coinHealth.ID } }" class="btn btn-info btn-sm btn-flat ml-8"
              tag="a">B
            </router-link>
          </div>
        </div>
        <!-- AltCoin Health Detail -->
        <div id="altcoin-health-detail" slot="content" :data-title="altCoinTitle" class="active">
          <div class="table-responsive">
            <div class="px-15">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <!-- Essential -->
                  <div class="box">
                    <label class="title mb-0">Essential</label>
                    <table class="table table-condensed table-striped mb-0">
                      <tbody>
                        <tr>
                          <th style="width: 35%">AltCoinID</th>
                          <td>
                            {{ coinHealth.ID }}
                            <router-link :to="{ name: 'payouts', query: { id: coinHealth.ID } }" tag="a">
                              <i class="fa fa-money ml-8"></i>
                            </router-link>
                            <router-link
                              :to="{ name: 'balance-sheet', query: { id: coinHealth.ID, buy: coinHealth.Buy, online: coinHealth.Online } }"
                              tag="a">
                              <i class="fa fa-globe ml-8"></i>
                            </router-link>
                          </td>
                        </tr>
                        <tr>
                          <th>Name</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('Name', 'Name', 'text')">
                              {{ coinHealth.Name }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Long Name</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('LongName', 'Long Name', 'text')">
                              {{ coinHealth.LongName }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Tag</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('Tag', 'Tag', 'text')"
                              v-html="formatStatus(coinHealth.Tag, 'tag')">
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Short Name</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('ShortName', 'Short Name', 'text')">
                              {{ coinHealth.ShortName }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Type</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('SecureCloud', 'SecureCloud', 'select')">
                              {{ getCurrencyType(coinHealth.SecureCloud) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Decimal Scale</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('DecimalScale', 'Decimal Scale', 'text')">
                              {{ coinHealth.DecimalScale ? coinHealth.DecimalScale : '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Block Explorer</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('BlockExplorer', 'Block Explorer', 'text')">
                              {{ coinHealth.BlockExplorer ? coinHealth.BlockExplorer : '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Exchange</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('Exchange', 'Exchange', 'text')">
                              {{ coinHealth.Exchange ? coinHealth.Exchange : '-' }}
                            </span>
                            <a v-if="coinHealth.Exchange" :href="coinHealth.Exchange" target="_blank" class="ml-8">
                              <i class="fa fa-globe"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <th>Paprika Coin Info</th>
                          <td>
                            <span class="pointer" style="word-break: break-all"
                              @click="launchUpdateAltCoinModal('PaprikaCoinInfo', 'Paprika Coin Info', 'text')">
                              {{ coinHealth.PaprikaCoinInfo || '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Online</th>
                          <td>
                            <span class="pointer" @click="updateField('Online')"
                              v-html="formatStatus(coinHealth.Online, 'online')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Buy</th>
                          <td>
                            <span class="pointer" @click="updateField('Buy')"
                              v-html="formatStatus(coinHealth.Buy, 'buy')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Deposit</th>
                          <td>
                            <span class="pointer" @click="updateField('DisableDeposit')"
                              v-html="formatStatus(coinHealth.DisableDeposit, 'deposit')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Payout</th>
                          <td>
                            <span class="pointer" @click="updateField('DisablePayout')"
                              v-html="formatStatus(coinHealth.DisablePayout, 'payout')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Bank Payout</th>
                          <td>
                            <span class="pointer" @click="updateField('DisableBankPayout')"
                              v-html="formatStatus(coinHealth.DisableBankPayout, 'payout')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Input Currency</th>
                          <td>
                            <span class="pointer" @click="updateField('IsInputCurrency')"
                              v-html="formatStatus(coinHealth.IsInputCurrency, 'inputCurrency')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>vFiat</th>
                          <td>
                            <span class="pointer" @click="updateField('vFiat')"
                              v-html="formatStatus(coinHealth.vFiat, 'vFiat')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Virtual Currency</th>
                          <td>
                            <span class="pointer" @click="updateField('VirtualCurrency')"
                              v-html="formatStatus(coinHealth.VirtualCurrency, 'virtualCurrency')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>LTR</th>
                          <td>
                            <span class="pointer" @click="updateField('LTR')"
                              v-html="formatStatus(coinHealth.LTR, 'ltr')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>LTR Balance</th>
                          <td>
                            <span>
                              <span class="pointer"
                                @click="launchUpdateAltCoinModal('LTRBalance', 'LTR Balance', 'text')">
                                {{ toFixed(coinHealth.LTRBalance) }}
                              </span>
                              <button v-if="coinHealth.LTR && coinHealth.Online"
                                v-tooltip="{ content: 'LTR Check', trigger: 'click hover focus' }"
                                class="btn btn-primary btn-sm btn-flat ml-8" @click="ltrCheck(coinHealth)">
                                <i class="fa fa-spinner fa-spin mr-8" v-if="loader === 'ltrCheck'"></i>
                                <span>L</span>
                              </button>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>UpdatedOn</th>
                          <td>
                            <span class="pointer">{{ coinHealth.UpdatedOn }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Daily BTC Price Tick</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('DailyBTCPriceTick', 'Daily BTC Price Tick', 'text')">
                              {{ toFixed(coinHealth.DailyBTCPriceTick) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Payment Check Interval</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('PaymentCheckInterval', 'Payment Check Interval', 'text')">
                              {{ coinHealth.PaymentCheckInterval }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Expiry Time</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('ExpiryTime', 'Expiry Time', 'text')">
                              {{ coinHealth.ExpiryTime + ' minutes' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Trading</th>
                          <td>
                            <span class="pointer" @click="updateField('DisableTrading')"
                              v-html="formatStatus(coinHealth.DisableTrading, 'trading')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Trading Engine</th>
                          <td>
                            <span class="pointer" @click="updateField('DisableTradingEngine')"
                              v-html="formatStatus(coinHealth.DisableTradingEngine, 'tradingEngine')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Trade Low Risk High Spread</th>
                          <td>
                            <span class="pointer" @click="updateField('TradeLowRiskHighSpread')"
                              v-html="formatStatus(coinHealth.TradeLowRiskHighSpread, 'risk')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Exclude From List</th>
                          <td>
                            <span class="pointer" @click="updateField('ExcludeFromList')"
                              v-html="formatStatus(coinHealth.ExcludeFromList, 'exclude')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Balance Refresh</th>
                          <td>
                            <span class="pointer" @click="updateField('BalanceRefreshScope')"
                              v-html="formatStatus(coinHealth.BalanceRefreshScope, 'BalanceRefreshScope')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Reverse Trade Pair</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('ReverseTradePair', 'Reverse Trade Pair', 'text')">
                              {{ coinHealth.ReverseTradePair }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>BTC not USDT Ticker</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('BTCnotUSDTTicker', 'BTC not USDT Ticker', 'text')">
                              {{ coinHealth.BTCnotUSDTTicker }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Get Info</th>
                          <td>
                            <span class="pointer" @click="updateField('GetInfo')"
                              v-html="formatStatus(coinHealth.GetInfo, 'info')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>OfflinePoolPreferred</th>
                          <td>
                            <span class="pointer" @click="updateField('OfflinePoolPreferred')"
                              v-html="formatStatus(coinHealth.OfflinePoolPreferred, 'offline')"></span>
                          </td>
                        </tr>

                        <tr>
                          <th>OfflinePoolChecks</th>
                          <td>
                            <span class="pointer" @click="updateField('OfflinePoolChecks')"
                              v-html="formatStatus(coinHealth.OfflinePoolChecks, 'offline')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Offline Payment Check URL</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('OfflinePaymentCheckURL', 'Offline Payment Check URL', 'text')">
                              {{ coinHealth.OfflinePaymentCheckURL || '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Coinectar Total Balance</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('CoinectarTotalBalance', 'Coinectar Total Balance', 'text')">
                              {{ coinHealth.CoinectarTotalBalance }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
                <div class="col-sm-12 col-md-6">
                  <!-- Health -->
                  <div class="box">
                    <label class="title">Health</label>
                    <table class="table table-condensed table-striped mb-0">
                      <tbody>
                        <tr>
                          <th style="width: 35%">Last Host Check</th>
                          <td :class="getBgClass(coinHealth.LastKnownCheckInHrs)" class="no-wrap">
                            {{ coinHealth.LastKnownCheckInHuman }}
                          </td>
                        </tr>
                        <tr>
                          <th>Last Ticker Check</th>
                          <td :class="getBgClass(coinHealth.LastKnownTickerCheckInHrs)" class="no-wrap">
                            {{ coinHealth.LastKnownTickerCheckInHuman }}
                          </td>
                        </tr>
                        <tr>
                          <th>Last Payment Check</th>
                          <td :class="getBgClass(coinHealth.LastPaymentCheckInHrs)" class="no-wrap">
                            {{ coinHealth.LastPaymentCheckInHuman }}
                          </td>
                        </tr>
                        <tr>
                          <th>Trade Update</th>
                          <td :class="getBgClass(coinHealth.TradeBuyUpdateInHrs)" class="no-wrap">
                            {{ coinHealth.TradeBuyUpdateInHuman }}
                          </td>
                        </tr>
                        <tr>
                          <th>Last Known State</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('LastKnownState', 'Last Known State', 'text')">
                              {{ coinHealth.LastKnownState }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Last Known Ticker Failure Count</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('LastKnownTickerFailureCount', 'Last Known Ticker Failure Count', 'text')">
                              {{ coinHealth.LastKnownTickerFailureCount }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Last Known Node Failure Count</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('LastKnownNodeFailureCount', 'Last Known Node Failure Count', 'text')">
                              {{ coinHealth.LastKnownNodeFailureCount }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>No Exchange Pool</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('NoExchangePool', 'No Exchange Pool', 'text')">
                              {{ coinHealth.NoExchangePool }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Support Mailbox</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('SupportMailbox', 'Support Mailbox', 'text')">
                              {{ coinHealth.SupportMailbox ? coinHealth.SupportMailbox : '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Support Detail</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('SupportDetail', 'Support Detail', 'textarea')">
                              <textarea v-model="coinHealth.SupportDetail" class="form-control" readonly
                                rows="3"></textarea>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- Fees & Rates -->
                  <div class="box">
                    <label class="title">Fees & Rates</label>
                    <table class="table table-condensed table-striped mb-0">
                      <tbody>
                        <tr>
                          <th style="width: 35%">Minimum Payout</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('MinimumPayout', 'Minimum Payout', 'text')">
                              {{ toFixed(coinHealth.MinimumPayout) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Miner Fee</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('MinerFee', 'Miner Fee', 'text')">
                              {{ toFixed(coinHealth.MinerFee) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Send Fee</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('SendFee', 'Send Fee', 'text')">
                              {{ toFixed(coinHealth.SendFee) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Send Fee Low</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('SendFeeLow', 'Send Fee Low', 'text')">
                              {{ coinHealth.SendFeeLow }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Interest</th>
                          <td>
                            <span class="pointer" @click="updateField('Interest')"
                              v-html="formatStatus(coinHealth.Interest, 'interest')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Interest Rate</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('InterestRate', 'Interest Rate', 'text')">
                              {{ coinHealth.InterestRate ? coinHealth.InterestRate : '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Fee Address</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('FeeAddress', 'Fee Address', 'text')">
                              {{ coinHealth.FeeAddress ? coinHealth.FeeAddress : '-' }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- Third Party Settings -->
                  <div class="box">
                    <label class="title">Third Party Settings</label>
                    <table class="table table-condensed table-striped mb-0">
                      <tbody>
                        <tr>
                          <th style="width: 35%">ChainNamePoloniex</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('ChainNamePoloniex', 'ChainNamePoloniex', 'text')">
                              {{ coinHealth.ChainNamePoloniex || '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Kraken Name</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('KrakenName', 'KrakenName', 'text')">
                              {{ coinHealth.KrakenName ? coinHealth.KrakenName : '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Fiat Bridge Fiat Name</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('FiatBridgeFiatName', 'Fiat Bridge Fiat Name', 'text')">
                              {{ coinHealth.FiatBridgeFiatName ? coinHealth.FiatBridgeFiatName : '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Fiat Bridge Coin Balance</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('FiatBridgeCoinBalance', 'Fiat Bridge Coin Balance', 'text')">
                              {{ toFixed(coinHealth.FiatBridgeCoinBalance) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Fiat Bridge Fiat Balance</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('FiatBridgeFiatBalance', 'Fiat Bridge Fiat Balance', 'text')">
                              {{ toFixed(coinHealth.FiatBridgeFiatBalance) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>FiatBridge Target Address</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('FiatBridgeTargetAddress', 'Fiat Bridge Target Address', 'text')">
                              {{ coinHealth.FiatBridgeTargetAddress ? coinHealth.FiatBridgeTargetAddress : '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>FiatBridge Target Address Tag</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('FiatBridgeTargetAddressTag', 'FiatBridge Target Address Tag', 'text')">
                              {{ coinHealth.FiatBridgeTargetAddressTag ? coinHealth.FiatBridgeTargetAddressTag : '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Chain</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('Chain', 'Chain', 'text')">
                              {{ coinHealth.Chain }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Chain Name</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('ChainName', 'Chain Name', 'text')">
                              {{ coinHealth.ChainName || '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>OKX</th>
                          <td>
                            <span class="pointer" @click="updateField('OKX')"
                              v-html="formatStatus(coinHealth.OKX, 'okx')">
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- Other Configurations -->
                  <div class="box">
                    <label class="title">Other Configurations</label>
                    <table class="table table-condensed table-striped mb-0">
                      <tbody>
                        <tr>
                          <th style="width: 35%">SecureCloud User</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('SecureCloudUser', 'Secure Cloud User', 'text')">
                              {{ coinHealth.SecureCloudUser || '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>SecureCloud Server</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('SecureCloudServer', 'SecureCloud Server', 'text')">
                              {{ coinHealth.SecureCloudServer || '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>SecureCloud Password</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('SecureCloudPassword', 'SecureCloud Password', 'text')">
                              {{ coinHealth.SecureCloudPassword || '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Unified Crypto Asset ID</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('unified_cryptoasset_id', 'Unified Crypto Asset ID', 'text')">
                              {{ coinHealth.unified_cryptoasset_id }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Trade Buy</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('TradeBuy', 'Trade Buy', 'text')">
                              {{ coinHealth.TradeBuy }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Trade Sell</th>
                          <td>
                            <span class="pointer" @click="launchUpdateAltCoinModal('TradeSell', 'Trade Sell', 'text')">
                              {{ coinHealth.TradeSell }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Available For Autonomy</th>
                          <td>
                            <span class="pointer" @click="updateField('AvailableForAutonomy')"
                              v-html="formatStatus(coinHealth.AvailableForAutonomy, 'available')"></span>
                          </td>
                        </tr>
                        <tr>
                          <th>Offline Pool XChange</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('OfflinePoolXChange', 'Offline Pool XChange', 'text')">
                              {{ coinHealth.OfflinePoolXChange }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>SecureCloud Wallet Backup</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('SCWalletBackup', 'SecureCloud Wallet Backup', 'text')">
                              {{ coinHealth.SecureCloudPassword || '-' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>SecureCloud Master Address</th>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal('SecureCloudMasterAddress', 'SecureCloud Master Address', 'text')">
                              {{ coinHealth.SecureCloudMasterAddress || '-' }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!coinHealth.Online" class="text-right">
              <button class="btn btn-danger" @click="deleteCoin(coinHealth.ID)">Delete Coin</button>
            </div>
          </div>
        </div>

        <!-- AltCoin XChange -->
        <div id="altcoin-xChange" slot="content"
          :data-title="'<span class=\'h-32 flex align-items-center\'>XChange Management</span>'">
          <div class="flex align-items-center justify-end mb-8 gap-8 flex-col-sm">
            <div class="flex align-items-center gap-8">
              <label class="flex align-items-center">
                <input v-model="active" type="checkbox" value="1" />
                Only Active
              </label>
              <label class="flex align-items-center">
                <input v-model="inUse" type="checkbox" value="1" />
                Only In Use
              </label>
              <label class="flex align-items-center">
                <input v-model="ltr" type="checkbox" value="1" />
                LTR
              </label>
            </div>
            <select v-model="xChangeNameID" aria-label="" class="form-control" style="width: 250px"
              @change="searchAltCoinXChanges()">
              <option value="">All XChange Server</option>
              <option v-for="(record, index) in exchangeList" :key="index" :value="record.ID">{{ record.Name }}
              </option>
            </select>
            <input v-model="keyword" aria-label="search" class="form-control" placeholder="Search..."
              style="width: 250px" type="text" @keyup="searchThreeChar($event)" @keydown="searchOnPaste($event)"
              @keydown.enter="searchAltCoinXChanges()" />
            <button class="btn btn-primary btn-flat btn-sm" @click="addXChange()">Add XChange
            </button>
          </div>
          <div v-if="meta && meta.total > 0">
            <pagination :meta="meta" @pageChange="updatePage"></pagination>
          </div>
          <div class="table-responsive">
            <table class="table table-condensed table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th class="pointer" @click="sort('ID')">
                    ID
                    <span v-if="sortKey === 'ID'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th>AltCoin</th>
                  <th class="pointer" @click="sort('RegisteredEmail')">
                    Registered Email
                    <span v-if="sortKey === 'RegisteredEmail'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('XChangeNameID')">
                    XChange Name
                    <span v-if="sortKey === 'XChangeNameID'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('XChangeServer')">
                    XChange Server
                    <span v-if="sortKey === 'XChangeServer'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('XChangeSecretKey')">
                    XChange Secret Key
                    <span v-if="sortKey === 'XChangeSecretKey'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('XChangeAPIKey')">
                    XChange API Key
                    <span v-if="sortKey === 'XChangeAPIKey'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('KcCoinBrokerUid')">
                    Broker Uid
                    <span v-if="sortKey === 'KcCoinBrokerUid'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('Fee')">
                    Fee
                    <span v-if="sortKey === 'Fee'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('IsPoolMaster')">
                    IsPoolMaster
                    <span v-if="sortKey === 'IsPoolMaster'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('IsActive')">
                    Active
                    <span v-if="sortKey === 'IsActive'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th>In Use</th>
                  <th class="pointer" @click="sort('Priority')">
                    Priority
                    <span v-if="sortKey === 'Priority'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th>Updated On</th>
                  <th class="pointer" @click="sort('Descope')">
                    Descope
                    <span v-if="sortKey === 'Descope'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('FiatBridge')">
                    Fiat Bridge
                    <span v-if="sortKey === 'FiatBridge'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('Stuck')">
                    Stuck
                    <span v-if="sortKey === 'Stuck'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('Nonce')">
                    Nonce
                    <span v-if="sortKey === 'Nonce'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('LTR')">
                    LTR
                    <span v-if="sortKey === 'LTR'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('ERC20')">
                    ERC20
                    <span v-if="sortKey === 'ERC20'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('ERC20ContractCode')">
                    ERC20 Contract Code
                    <span v-if="sortKey === 'ERC20ContractCode'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('BlockExplorerMode')">
                    Block Explorer Mode
                    <span v-if="sortKey === 'BlockExplorerMode'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('CreatedOn')">
                    Created On
                    <span v-if="sortKey === 'CreatedOn'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th class="pointer" @click="sort('UpdatedOn')">
                    Updated On
                    <span v-if="sortKey === 'UpdatedOn'" class="ml-4">
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th style="min-width: 140px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="altCoinXchangeList.length > 0">
                  <template v-for="(record, index) in altCoinXchangeList">
                    <tr :key="index">
                      <td>
                        <span class="flex pointer text-success" @click="record.isExpanded = !record.isExpanded">
                          <svg v-if="!record.isExpanded" class="w-24 h-24" fill="none" stroke="currentColor"
                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2"></path>
                          </svg>
                          <svg v-else class="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2"></path>
                          </svg>
                        </span>
                      </td>
                      <td>
                        <div class="flex align-items-center">
                          {{ record.ID }}
                          <button class="btn btn-primary btn-flat btn-sm ml-8" @click="getInfoBalance(record)">Balance
                          </button>
                          <a target="_blank" :href="`${record.BlockExplorer}${record.XChangeAddressPool.Address}`"
                            class="ml-8" v-if="record.BlockExplorer && record.XChangeAddressPool">
                            <i class="fa fa-globe" />
                          </a>
                        </div>
                      </td>
                      <td>
                        <span class="pointer no-wrap" @click="launchUpdateAltCoinIDModal(record)">
                          {{ altCoinIDToName(record.AltCoinID) }}
                        </span>
                      </td>
                      <td>
                        <span class="pointer no-wrap"
                          @click="launchUpdateAltCoinXChangeModal(record, 'RegisteredEmail', 'Registered Email', 'text')">
                          {{ record.RegisteredEmail }}
                        </span>
                      </td>
                      <td>
                        <span class="pointer"
                          @click="launchUpdateAltCoinXChangeModal(record, 'XChangeNameID', 'XChange Name', 'select')">
                          {{ getExchangeName(record.XChangeNameID) }}
                        </span>
                      </td>
                      <td class="no-wrap">
                        <span class="pointer"
                          @click="launchUpdateAltCoinXChangeModal(record, 'XChangeServer', 'XChange Server', 'text')">
                          {{ record.XChangeServer }}
                        </span>
                      </td>
                      <td class="no-wrap">
                        <span class="pointer"
                          @click="launchUpdateAltCoinXChangeModal(record, 'XChangeSecretKey', 'XChange Secret Key', 'text')">
                          {{ excerpt(record.XChangeSecretKey, 8) }}
                        </span>
                      </td>
                      <td class="no-wrap">
                        <span class="pointer"
                          @click="launchUpdateAltCoinXChangeModal(record, 'XChangeAPIKey', 'XChange API Key', 'text')">
                          {{ excerpt(record.XChangeAPIKey, 8) }}
                        </span>
                      </td>
                      <td class="no-wrap">
                        <span class="pointer"
                          @click="launchUpdateAltCoinXChangeModal(record, 'KcCoinBrokerUid', 'KcCoin Broker Uid', 'text')">
                          {{ record.KcCoinBrokerUid || "-" }}
                        </span>
                      </td>
                      <td>
                        <span class="pointer" @click="launchUpdateAltCoinXChangeModal(record, 'Fee', 'Fee', 'text')">
                          {{ record.Fee }}
                        </span>
                      </td>
                      <td>
                        <span class="pointer"
                          @click="launchUpdateAltCoinXChangeModal(record, 'IsPoolMaster', 'Pool Master', 'text')">
                          {{ record.IsPoolMaster }}
                        </span>
                      </td>
                      <td>
                        <span @click="updateXChangeField(record, 'IsActive')"
                          v-html="formatStatus(record.IsActive, 'active')"></span>
                      </td>
                      <td>
                        <span v-if="record.XChangeAddressPool" @click="updateXChangeAddressPoolField(record, 'IsInUse')"
                          v-html="formatStatus(record.XChangeAddressPool.IsInUse, 'inUse')"></span>
                      </td>
                      <td>
                        <span @click="launchUpdateAltCoinXChangeModal(record, 'Priority', 'Priority', 'select')"
                          v-html="formatStatus(record.Priority, 'priority')"></span>
                      </td>
                      <td class="no-wrap">
                        <span v-if="record.XChangeAddressPool"
                          v-html="formatDate(record.XChangeAddressPool.UpdatedOn)"></span>
                      </td>
                      <td>
                        <span @click="updateXChangeField(record, 'Descope')"
                          v-html="formatStatus(record.Descope, 'descope')"></span>
                      </td>
                      <td>
                        <span @click="updateXChangeField(record, 'FiatBridge')"
                          v-html="formatStatus(record.FiatBridge, 'fiatBridge')"></span>
                      </td>
                      <td>
                        <span class="pointer"
                          @click="launchUpdateAltCoinXChangeModal(record, 'Stuck', 'Stuck', 'text')">
                          {{ record.Stuck }}
                        </span>
                      </td>
                      <td>
                        <span class="pointer"
                          @click="launchUpdateAltCoinXChangeModal(record, 'Nonce', 'Nonce', 'text')">
                          {{ record.Nonce }}
                        </span>
                      </td>
                      <td>
                        <span @click="updateXChangeField(record, 'LTR')"
                          v-html="formatStatus(record.LTR, 'ltr')"></span>
                      </td>
                      <td>
                        <span @click="updateXChangeField(record, 'ERC20')"
                          v-html="formatStatus(record.ERC20, 'erc20')"></span>
                      </td>
                      <td>
                        <span class="pointer"
                          @click="launchUpdateAltCoinXChangeModal(record, 'ERC20ContractCode', 'ERC20 Contract Code', 'text')">
                          {{ record.ERC20ContractCode ? record.ERC20ContractCode : '-' }}
                        </span>
                      </td>
                      <td>
                        <span @click="updateXChangeField(record, 'BlockExplorerMode')"
                          v-html="formatStatus(record.BlockExplorerMode, 'explorerMode')"></span>
                      </td>
                      <td class="no-wrap">{{ formatDate(record.CreatedOn) }}</td>
                      <td class="no-wrap">{{ formatDate(record.UpdatedOn) }}</td>
                      <td>
                        <div class="d-flex">
                          <button class="btn btn-primary btn-sm mr-8" @click="clone(record.ID)">Clone</button>
                          <button class="btn btn-danger btn-sm" type="button"
                            @click="deleteXChangeItem(record.ID)">Delete</button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="record.isExpanded" :key="'address_pool_' + index">
                      <td colspan="22">
                        <table class="table table-striped table-condensed mb-0">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Address</th>
                              <th>Tag</th>
                              <th>Amount</th>
                              <th>IsPoolMaster</th>
                              <th>In Use</th>
                              <th>Created On</th>
                              <th>Updated On</th>
                              <th>Last Known Transaction ID</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-if="record.XChangeAddressPool">
                              <tr>
                                <td>
                                  <router-link
                                    :to="{ name: 'dashboard', query: { xc: 'XC' + record.XChangeAddressPool.ID } }"
                                    tag="a">
                                    {{ record.XChangeAddressPool.ID }}
                                  </router-link>
                                </td>
                                <td>{{ record.XChangeAddressPool.Address }}</td>
                                <td>
                                  <span @click="launchUpdateAltCoinXChangePoolModal(record, 'Tag', 'Tag', 'text')"
                                    class="pointer">
                                    {{ record.XChangeAddressPool.Tag ? record.XChangeAddressPool.Tag : '-' }}
                                  </span>
                                </td>
                                <td>{{ toFixed(record.XChangeAddressPool.Amount) }}</td>
                                <td>{{ record.XChangeAddressPool.IsPoolMaster }}</td>
                                <td>
                                  <span @click="updateXChangeAddressPoolField(record, 'IsInUse')"
                                    v-html="formatStatus(record.XChangeAddressPool.IsInUse, 'inUse')"></span>
                                </td>
                                <td>{{ formatDate(record.XChangeAddressPool.CreatedOn) }}</td>
                                <td>{{ formatDate(record.XChangeAddressPool.UpdatedOn) }}</td>
                                <td>
                                  <router-link v-if="record.XChangeAddressPool.LastKnownTransactionID"
                                    :to="{ name: 'transaction-detail', params: { id: generateId(record.XChangeAddressPool.LastKnownTransactionID) } }"
                                    tag="a">
                                    {{ record.XChangeAddressPool.LastKnownTransactionID }}
                                  </router-link>
                                  <span v-else>{{ record.XChangeAddressPool.LastKnownTransactionID }}</span>
                                </td>
                                <td>
                                  <template v-if="record.XChangeServer.indexOf('kucoin') !== -1">
                                    <button class="btn btn-flat btn-primary mr-4"
                                      @click="launchDataModel(record, 'deposits')">Deposit List</button>
                                    <button class="btn btn-flat btn-primary mr-4"
                                      @click="launchDataModel(record, 'withdrawals')">Widthdrawal List</button>
                                    <button class="btn btn-flat btn-primary"
                                      @click="launchDataModel(record, 'ledgers')">Ledgers</button>
                                  </template>
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr>
                                <td colspan="9">No record found!</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </td>
                      <td colspan="8"></td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="26">No record found!</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div v-if="meta && meta.total > 0">
            <pagination :meta="meta" @pageChange="updatePage"></pagination>
          </div>
        </div>
        <!-- Transactions -->
        <div id="altcoin-transactions" slot="content"
          :data-title="'<span class=\'h-32 flex align-items-center\'>Transactions</span>'">
          <transactions :id="altCoinID"></transactions>
        </div>
      </va-tabs>
    </div>
    <!-- Update AltCoin XChange Modal -->
    <update-alt-coin-x-change-modal></update-alt-coin-x-change-modal>
    <!-- Update AltCoin XChange Address Pool Modal -->
    <update-alt-coin-exchange-address-pool-modal></update-alt-coin-exchange-address-pool-modal>
    <!-- Update AltCoin Modal -->
    <update-alt-coin-modal></update-alt-coin-modal>
    <!-- Balance Refresh Dialog -->
    <balance-refresh-result-dialog></balance-refresh-result-dialog>
    <!-- Update XChange AltCoinID Modal -->
    <update-xchange-altcoin-id-modal></update-xchange-altcoin-id-modal>
    <!-- KuCoin Data Modal -->
    <KuCoinDataModal></KuCoinDataModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VaTabs from "@/components/VATabs";
import { EXCHANGE_LIST, START_LOADING, STOP_LOADING } from "@/store/keys";
import {
  dateDiffInHours,
  diffInHuman,
  formatDate,
  generateIconUrl,
  getOptions,
  logout,
  saveNotification,
  toFixed,
} from "@/lib/utils";
import Pagination from "@/components/Pagination";
import UpdateAltCoinXChangeModal from "@/components/modals/UpdateAltCoinXChangeModal";
import UpdateAltCoinModal from "@/components/modals/UpdateAltCoinModal";
import UpdateXChangeAltCoinIDModal from "@/components/modals/UpdateXChangeAltCoinIDModal";
import UpdateAltCoinExchangeAddressPoolModal from "@/components/modals/UpdateAltCoinXChangeAddressPoolModal";
import BalanceRefreshResultDialog from "../components/modals/BalanceRefreshResultDialog";
import Transactions from "@/components/coin-health-detail/Transactions";
import KuCoinDataModal from "@/components/modals/KuCoinDataModal.vue";

export default {
  name: "CoinHealthDetail",
  components: {
    Transactions,
    BalanceRefreshResultDialog,
    UpdateAltCoinExchangeAddressPoolModal,
    VaTabs,
    UpdateAltCoinModal,
    UpdateAltCoinXChangeModal,
    "update-xchange-altcoin-id-modal": UpdateXChangeAltCoinIDModal,
    Pagination,
    KuCoinDataModal,
  },
  data() {
    return {
      altCoinID: "",
      coinHealth: null,
      altCoinXchangeList: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      active: 1,
      inUse: 0,
      ltr: false,
      keyword: "",
      xChangeNameID: "",
      sortKey: "CreatedOn",
      order: "desc",
      loader: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "currentUser",
      "jwtToken",
      "exchangeList",
      "altCoins",
    ]),
    altCoinTitle() {
      const icon = generateIconUrl(this.coinHealth.Name);
      return `<img class="h-32 mr-8" src="${icon}" alt=""/>AltCoin Health Detail`;
    },
  },
  watch: {
    active(val) {
      this.getPaginatedAltCoinXChangeList(this.coinHealth.ID, 1);
    },
    inUse(val) {
      this.getPaginatedAltCoinXChangeList(this.coinHealth.ID, 1);
    },
    ltr(val) {
      this.getPaginatedAltCoinXChangeList(this.coinHealth.ID, 1);
    },
  },
  methods: {
    altCoinIDToName(id) {
      const altCoin = this.altCoins.filter(a => a.ID === id);
      if (altCoin.length > 0) {
        return `${altCoin[0].Name} (${altCoin[0].LongName})`;
      }
      return id;
    },
    getInfo(record) {
      const url = process.env.VUE_APP_API_URL;
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
      this.$http.get(`${url}/GetInfo`, {
        params: {
          AltCoinID: record.ID,
          Name: "B",
          Secure: "AA",
        },
      }).then(response => {
        this.$swal.close();
        if (response.data.message.toLowerCase() === "no permission") {
          logout();
        } else {
          this.$toast.fire("", response.data.message, "success");
        }
      }).catch(error => {
        this.$swal.close();
        console.log(error);
      });
    },
    async poolCheck(record) {
      this.$swal.fire({
        text: "Pool check only",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          this.loader = "poolCheck";
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          const payload = {
            Name: "B",
            AltCoinID: record.ID,
            Secure: "AA",
            PoolCheck: 1,
          };
          this.$http.get(`${apiBaseUrl}/GetInfo`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.loader = "";
            if (response.data.status_code === 200) {
              if (response.data.result.toLowerCase() === "success") {
                this.$toast.fire("", response.data.message, "success");
                saveNotification(response.data.message);
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            this.loader = "";
            console.log(error);
          });
        }
      });
    },
    async poolCheckAndConsolidation(record) {
      const options = [];
      options.all = "All XChange Server";
      this.exchangeList.filter(e => e.Online).forEach(e => {
        options[e.ID] = e.Name;
      });
      this.$swal.fire({
        text: "Pool check and consolidate",
        icon: "warning",
        input: "select",
        inputOptions: options,
        inputValue: "all",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value !== undefined) {
          this.loader = "consolidate";
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          let payload = {
            Name: "B",
            AltCoinID: record.ID,
            Secure: "AA",
            PoolCheck: 1,
            Consolidate: 1,
          };
          if (value !== "" && value !== null) {
            payload = Object.assign(payload, {
              XChangeNameID: Number(value),
            });
          }
          this.$http.get(`${apiBaseUrl}/GetInfo`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.loader = "";
            if (response.data.status_code === 200) {
              if (response.data.result.toLowerCase() === "success") {
                this.$toast.fire("", response.data.message, "success");
                saveNotification(response.data.message);
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            this.loader = "";
            console.log(error);
          });
        }
      });
    },
    async validate(text = "") {
      return await this.$swal.fire({
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        return !!(value && value === true);
      });
    },
    async ltrCheck(record) {
      const result = await this.validate("LTR check");
      if (result) {
        this.loader = "ltrCheck";
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: {
            AltCoinID: record.ID,
            Name: "B",
            Secure: "AA",
            LTRCheck: 1,
          },
          withCredentials: true,
        }).then(response => {
          this.loader = "";
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", "LTR check performed successfully", "success");
              saveNotification("LTR check performed successfully");
              this.getAltCoinHealthById(record.ID, false);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          this.loader = "";
          console.log(error);
        });
      }
    },
    resetIndex() {
      this.expandedRowIndex = 0;
    },
    getAltCoinHealthById(id, loader = true) {
      if (loader) {
        this.$store.commit(START_LOADING);
      }
      if (this.$route.query.keyword) {
        this.keyword = this.$route.query.keyword.trim();
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=AltCoinHealth`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        if (response.status === 200) {
          this.coinHealth = response.data.data;
          if (this.coinHealth) {
            this.coinHealth = {
              ...this.coinHealth,
              LastKnownCheckInHrs: this.dateDiffInHours(this.coinHealth.LastKnownCheck),
              LastKnownCheckInHuman: this.diffInHuman(this.coinHealth.LastKnownCheck),
              LastKnownTickerCheckInHrs: this.dateDiffInHours(this.coinHealth.LastKnownTickerCheck),
              LastKnownTickerCheckInHuman: this.diffInHuman(this.coinHealth.LastKnownTickerCheck),
              LastPaymentCheckInHrs: this.dateDiffInHours(this.coinHealth.LastPaymentCheck),
              LastPaymentCheckInHuman: this.diffInHuman(this.coinHealth.LastPaymentCheck),
              TradeBuyUpdateInHrs: this.dateDiffInHours(this.coinHealth.TradeBuyUpdate),
              TradeBuyUpdateInHuman: this.diffInHuman(this.coinHealth.TradeBuyUpdate),
              TradeSellUpdateInHrs: this.dateDiffInHours(this.coinHealth.TradeSellUpdate),
              TradeSellUpdateInHuman: this.diffInHuman(this.coinHealth.TradeSellUpdate),
            };
          }
          if (this.$route.query.tb && this.$route.query.tb === "xChange") {
            this.$nextTick(() => {
              $("a[href=\"#altcoin-xChange\"]").click();
            });
            this.$router.replace({
              ...this.$router.currentRoute,
              query: {},
            });
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.$toast.fire("", error.data.message, "error");
      });
    },
    updatePage(page) {
      this.resetIndex();
      this.getPaginatedAltCoinXChangeList(this.coinHealth.ID, page, true);
    },
    searchOnPaste(e) {
      if (e.code === "KeyV" && e.ctrlKey) {
        setTimeout(() => {
          this.searchAltCoinXChanges();
        }, 50);
      }
    },
    searchThreeChar(e) {
      if (this.keyword.trim().length >= 3) {
        setTimeout(() => {
          this.searchAltCoinXChanges();
        }, 50);
      }
    },
    searchAltCoinXChanges() {
      this.resetIndex();
      const id = this.$route.params.id;
      this.getPaginatedAltCoinXChangeList(id);
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getPaginatedAltCoinXChangeList(this.coinHealth.ID, 1, false);
    },
    getPaginatedAltCoinXChangeList(id, page = 1, loading = false, xChange = "") {
      if (loading) {
        this.$swal.fire({
          title: "",
          text: "Loading...",
          showConfirmButton: false,
          backdrop: true,
        });
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      let params = {
        id,
        page,
        keyword: this.keyword ? this.keyword.toString().trim() : "",
        xChangeNameID: this.xChangeNameID,
        limit: this.meta.limit,
        order: this.sortKey,
        orderBy: this.order,
        type: xChange,
        ltr: this.ltr ? 1 : 0,
      };
      if (this.active) {
        params = Object.assign(params, {
          active: 1,
        });
      }
      if (this.inUse) {
        params = Object.assign(params, {
          inUse: 1,
        });
      }
      this.$http.get(`${apiBaseUrl}/?Call=ListAltCoinXChange`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: params,
      }).then(response => {
        if (loading) {
          this.$swal.close();
        }
        if (response.status === 200) {
          this.altCoinXchangeList = response.data.data.records;
          if (this.altCoinXchangeList.length) {
            this.altCoinXchangeList = this.altCoinXchangeList.map(r => {
              return {
                ...r,
                isExpanded: false,
              };
            });
          }
          this.meta = response.data.data.meta;
          if (this.$route.query.q) {
            const q = this.$route.query.q;
            if (this.$route.query.keyword) {
              this.keyword = this.$route.query.keyword;
            }
            if (q === "xChange") {
              this.$router.replace({
                ...this.$router.currentRoute,
                query: {},
              });
              $("a[href=\"#altcoin-xChange\"]").click();
            }
          }
        }
      }).catch(error => {
        if (loading) {
          this.$swal.close();
        }
        this.$toast.fire("", error.data.message, "error");
      });
    },
    getAltCoinXChangeById(id, index) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=AltCoinXChange`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id,
        },
      }).then(response => {
        if (response.status === 200) {
          const record = this.altCoinXchangeList[index];
          let data = response.data.data;
          data = {
            ...data,
            isExpanded: record ? record.isExpanded : false,
          };
          this.altCoinXchangeList.splice(index, 1, data);
        }
      }).catch(error => {
        this.$toast.fire("", error.data.message, "error");
      });
    },
    getExchangeName(id) {
      const xChange = this._.find(this.exchangeList, { ID: id });
      return xChange ? xChange.Name : "";
    },
    generateStatusLabel(key, status) {
      let label = "";
      switch (key) {
        case "ltr":
        case "erc20":
        case "explorerMode":
          label = status ? "On" : "Off";
          break;
        case "buy":
          label = status ? "Enabled" : "Disabled";
          break;
        case "online":
          label = status ? "Online" : "Offline";
          break;
        case "deposit":
        case "payout":
        case "trading":
        case "tradingEngine":
          label = status ? "Disabled" : "Enabled";
          break;
        case "inputCurrency":
        case "virtualCurrency":
        case "interest":
        case "interestRate":
        case "info":
        case "available":
        case "risk":
        case "descope":
        case "active":
        case "fiatBridge":
        case "exclude":
        case "inUse":
        case "offline":
        case "tag":
        case "BalanceRefreshScope":
        case "okx":
          label = status ? "Yes" : "No";
          break;
        case "priority":
          switch (status) {
            case 1:
              label = "Critical";
              break;
            case 2:
              label = "Significant";
              break;
            case 3:
              label = "Moderate";
              break;
            case 4:
              label = "Elevated";
              break;
            case 5:
              label = "Default";
              break;
          }
          break;
        case "vFiat":
          switch (status) {
            case 0:
              label = "Crypto";
              break;
            case 1:
              label = "Virtual fiat";
              break;
            case 2:
              label = "virtual shares";
              break;
            case 3:
              label = "virtual commodities";
              break;
            case 4:
              label = "virtual indices";
              break;
            case 5:
              label = "virtual bond";
              break;
          }
          break;
      }
      return label;
    },
    generateStatusClass(key, status) {
      let lblClass = "";
      switch (key) {
        case "online":
        case "buy":
        case "ltr":
        case "inputCurrency":
        case "virtualCurrency":
        case "interest":
        case "interestRate":
        case "info":
        case "available":
        case "risk":
        case "fiatBridge":
        case "erc20":
        case "explorerMode":
        case "active":
        case "inUse":
        case "offline":
        case "tag":
        case "BalanceRefreshScope":
        case "okx":
          lblClass = status ? "label-success" : "label-danger";
          break;
        case "deposit":
        case "payout":
        case "trading":
        case "tradingEngine":
        case "descope":
        case "exclude":
          lblClass = status ? "label-danger" : "label-success";
          break;
        case "priority":
        case "vFiat":
          lblClass = "label-default";
          break;
      }
      return lblClass;
    },
    formatStatus(status, key) {
      const formattedStatus = this.generateStatusLabel(key, status);
      const lblClass = this.generateStatusClass(key, status);
      return "<label class=\"label " + lblClass + " pointer\">" + formattedStatus + "</label>";
    },
    getCurrencyType(type) {
      let label = "";
      switch (type) {
        case 1:
          label = "SecureCloud";
          break;
        case 2:
          label = "Fiat";
          break;
        case 3:
          label = "XChange";
          break;
      }
      return label;
    },
    generateId(id) {
      return "T" + id.toString().padStart(9, "0");
    },
    toFixed(val) {
      return toFixed(val);
    },
    generateIconUrl(coinHealth) {
      return generateIconUrl(coinHealth.Name);
    },
    getBgClass(val) {
      return val <= 1 ? "bg-success" : "bg-danger";
    },
    dateDiffInHours(val) {
      return dateDiffInHours(val);
    },
    diffInHuman(val) {
      return diffInHuman(val);
    },
    formatDate(val) {
      return formatDate(val);
    },
    excerpt(str, length = 30) {
      if (str) {
        return str.length > length ? str.substr(0, length) + "..." : str;
      }
      return str;
    },
    getOptions(key) {
      return getOptions(key);
    },
    launchDataModel(record, action) {
      const altCoin = this._.find(this.altCoins, { ID: record.AltCoinID });
      this.$bus.$emit("KuCoinDataModal", {
        record,
        action,
        currency: altCoin.LongName || null,
      });
    },
    launchUpdateAltCoinXChangeModal(record, key, label, type) {
      const index = this.altCoinXchangeList.indexOf(record);
      const options = this.getOptions(key);
      this.$bus.$emit("update-xchange-modal", {
        title: "Update " + label,
        valueLabel: label,
        xChange: {
          ID: record.ID,
          Key: key === "KcCoinBrokerUid" ? "kc_broker_uid" : key,
          Value: record[key],
        },
        type,
        options,
        confirm: () => {
          this.getAltCoinXChangeById(record.ID, index);
        },
      });
    },
    launchUpdateAltCoinIDModal(record) {
      const index = this.altCoinXchangeList.indexOf(record);
      this.$bus.$emit("updateXchangeAltCoinIDModal", {
        record: record,
        confirm: () => {
          this.altCoinXchangeList.splice(index, 1);
        },
      });
    },
    updateXChangeField(record, key) {
      const index = this.altCoinXchangeList.indexOf(record);
      let value = record[key];
      value = (value === 1 || value === true) ? 0 : 1;
      const form = {
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: record.ID,
        Key: key,
        Value: value,
        output: "json",
      };
      const payload = JSON.parse(JSON.stringify(form));
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=AltCoinXChangeUpdate`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.getAltCoinXChangeById(record.ID, index);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    launchUpdateAltCoinXChangePoolModal(record, key, label, type) {
      const index = this.altCoinXchangeList.indexOf(record);
      const options = this.getOptions(key);
      this.$bus.$emit("update-xchange-address-pool-modal", {
        title: "Update " + label,
        valueLabel: label,
        xChange: {
          ID: record.XChangeAddressPool.ID,
          Key: key,
          Value: record.XChangeAddressPool[key],
        },
        type,
        options,
        confirm: () => {
          this.getAltCoinXChangeById(record.ID, index);
        },
      });
    },
    updateXChangeAddressPoolField(record, key) {
      const index = this.altCoinXchangeList.indexOf(record);
      let value = record.XChangeAddressPool[key];
      if (key === "IsInUse") {
        value = (value === 1 || value === true) ? 0 : 1;
      }
      const form = {
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: record.XChangeAddressPool.ID,
        Key: key,
        Value: value,
        output: "json",
      };
      const payload = JSON.parse(JSON.stringify(form));
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=AltCoinXChangeAddressPoolUpdate`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.getAltCoinXChangeById(record.ID, index);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    launchUpdateAltCoinModal(key, label, type) {
      const keys = ["DailyBTCPriceTick", "LTRBalance", "FiatBridgeCoinBalance", "FiatBridgeFiatBalance", "MinimumPayout"];
      const options = this.getOptions(key);
      this.$bus.$emit("update-altCoin-modal", {
        title: "Update " + label,
        valueLabel: label,
        altCoin: {
          ID: this.coinHealth.ID,
          Key: key,
          Value: keys.indexOf(key) !== -1 ? toFixed(this.coinHealth[key]) : this.coinHealth[key],
        },
        type,
        options,
        confirm: () => {
          this.getAltCoinHealthById(this.coinHealth.ID, false);
        },
      });
    },
    updateField(key, type = "boolean") {
      let value = this.coinHealth[key];
      if (type === "boolean") {
        value = (value === 1 || value === true) ? 0 : 1;
      }
      const form = {
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        AltCoinID: this.coinHealth.ID,
        Key: key,
        Value: value,
        output: "json",
      };
      const payload = JSON.parse(JSON.stringify(form));
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=AltCoinUpdate`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.getAltCoinHealthById(this.coinHealth.ID, false);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    addXChange() {
      this.$swal.fire({
        text: "Are you sure want to add new XChange?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/v2REAPI?Call=AltCoinXChangeInsert`, {
            params: {
              AltCoinID: this.coinHealth.ID,
              MerchantID: this.currentUser.ID,
              APIKey: this.currentUser.APIKey,
              output: "json",
            },
          }).then(response => {
            if (response.data.status_code === 200) {
              if (response.data.result.toLowerCase() === "success") {
                this.$toast.fire("", response.data.message, "success");
                this.getPaginatedAltCoinXChangeList(this.coinHealth.ID);
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    },
    getInfoBalance(record) {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/GetInfo`, {
        params: {
          AltCoinID: this.coinHealth.ID,
          Name: "B",
          Secure: "AA",
          XChangeID: record.ID,
        },
      }).then(response => {
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$bus.$emit("balance-refresh-dialog", {
            data: response.data.message,
          });
          saveNotification(response.data.message);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    deleteCoin(id) {
      this.$swal.fire({
        text: "Are you sure want to delete this coin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: {
              Call: "DeleteCoin",
              MerchantID: 1,
              APIKey: "_",
              AltCoinID: id,
              output: "json",
            },
          }).then(response => {
            if (response.data && response.data.status_code === 200) {
              this.$toast.fire("", response.data.message, "success");
              this.$router.push({ name: "coin-health" });
            } else {
              this.$toast.fire("", response.data.message, "error");
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    },
    deleteXChangeItem(id) {
      this.$swal.fire({
        text: "Are you sure want to delete XChange?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/v2REAPI?Call=DeleteXChangeID`, {
            params: {
              ID: id,
              MerchantID: this.currentUser.ID,
              APIKey: this.currentUser.APIKey,
              output: "json",
            },
          }).then(response => {
            if (response.data.status_code === 200) {
              if (response.data.result.toLowerCase() === "success") {
                this.$toast.fire("", response.data.message, "success");
                this.getPaginatedAltCoinXChangeList(this.coinHealth.ID);
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    },
    clone(id) {
      this.$swal.fire({
        text: "Are you sure want to clone this XChange?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/v2REAPI?Call=CloneXChangeID`, {
            params: {
              ID: id,
              MerchantID: this.currentUser.ID,
              APIKey: this.currentUser.APIKey,
              output: "json",
            },
          }).then(response => {
            if (response.data.status_code === 200) {
              if (response.data.result.toLowerCase() === "success") {
                this.$toast.fire("", response.data.message, "success");
                this.getPaginatedAltCoinXChangeList(this.coinHealth.ID);
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    },
  },
  mounted() {
    this.altCoinID = this.$route.params.id;
    if (this.altCoinID) {
      this.getAltCoinHealthById(this.altCoinID);
      if (this.$route.query.id) {
        this.keyword = this.$route.query.id;
      }
      // let xChange = false
      if (this.$route.query.q) {
        // xChange = this.$route.query.q
        if (this.$route.query.keyword) {
          this.keyword = this.$route.query.keyword;
        }
        $("a[href=\"#altcoin-xChange\"]").click();
      }
      if (this.$route.hash) {
        setTimeout(() => {
          $("a[href=\"" + this.$route.hash + "\"]").click();
        }, 450);
      }
      this.getPaginatedAltCoinXChangeList(this.altCoinID, 1, false, "");
    }
    this.$store.dispatch(EXCHANGE_LIST).catch(() => {
    });
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
