<template>
  <div id="bsModalUpdateXChangeAltCoinID" aria-labelledby="bsModalLabel" class="modal fade"
       data-backdrop="static" data-keyboard="false"
       data-toggle="modal"
       role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span
              aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">Update XChange AltCoin ID</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <input v-model="form.ID" type="hidden"/>
              <input v-model="form.Key" type="hidden"/>
              <ValidationProvider v-slot="{ errors }" name="AltCoin ID" class="form-group"
                                  rules="required" tag="div">
                <label>AltCoin ID</label>
                <select v-model="form.TargetAltCoinID" aria-label="" class="form-control">
                  <option v-for="(altCoin, index) in altCoins" :key="index" :value="altCoin.ID">
                    {{ `${altCoin.Name} (${altCoin.LongName})` }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Save</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { logout, saveNotification } from "@/lib/utils";

export default {
  name: "UpdateXChangeAltCoinIDModal",
  data() {
    return {
      isLoading: false,
      record: null,
      form: {
        MerchantID: "",
        APIKey: "",
        ID: "",
        TargetAltCoinID: "",
        output: "json",
      },
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "altCoins",
    ]),
  },
  methods: {
    confirm: function () {
      this.confirmHook();
      this.close();
    },
    onSubmit: function () {
      if (this.form.TargetAltCoinID === this.record.AltCoinID) {
        this.$toast.fire("", "Please change the AltCoin ID", "warning");
        return;
      }
      this.isLoading = true;
      const payload = JSON.parse(JSON.stringify(this.form));
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=ChangeXChangeID`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        this.isLoading = false;
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.confirm();
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalUpdateXChangeAltCoinID").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("updateXchangeAltCoinIDModal", (args) => {
      this.record = args.record;
      this.form = {
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: this.record.ID,
        TargetAltCoinID: this.record.AltCoinID,
        output: "json",
      };
      this.confirmHook = args.confirm;
      $("#bsModalUpdateXChangeAltCoinID").modal("show");
    });
  },
};
</script>

<style scoped></style>
